import { DialogTag } from '@pixelgenau/ngx-px-dialog';

export interface ChildM030C90GalleryOverlayDialogData {
    image: string;
    alt?: string | null;
}

export const ChildM030C90GalleryOverlayDialogTag = new DialogTag<ChildM030C90GalleryOverlayDialogData, boolean>(
    'child-m030-c090-gallery-overlay-dialog',
    async () => {
        const { ChildM030C090GalleryOverlayComponent } = await import('./child-m030-c090-gallery-overlay.component');
        return ChildM030C090GalleryOverlayComponent;
    },
);
